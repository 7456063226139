import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import Link from "../components/Link";
import {get} from "lodash";

export default function RenderProductsSection(slice) {
  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const ctaLink = get(slice, "primary.cta_link[0].text");
  const ctaText = get(slice, "primary.cta_text[0].text");

  const styles = {
    maxWidth: "none",
  };

  const renderProductsList = (items) => {
    return items.map((el, i) => {
      const externalLink = get(el, "link_to[0].text").includes("http");

      return (
        <div key={i} className="product">
          {externalLink ? (
            <Link url={get(el, "link_to[0].text")} target="_blank">
              <div className="image">
                <img src={el.image.url} alt="Good News Products" />
              </div>
              <div className="content">
                <PrismicRichText render={el.content} />
              </div>
            </Link>
          ) : (
            <Link to={{pathname: "/products", directLink: el.content[0].text}}>
              <div className="image">
                <img src={el.image.url} alt="Good News Products" />
              </div>
              <div className="content">
                <PrismicRichText render={el.content} />
              </div>
            </Link>
          )}
        </div>
      );
    });
  };
  return (
    <section
      className="product-section standard-padding"
      style={backgroundStyle}
    >
      <div className="wrapper">
        <div className="flex-wrapper">
          <div className="content-wrapper" style={styles}>
            <PrismicRichText render={slice.primary.content} />
          </div>

          <div className="button-wrapper">
            {ctaLink && ctaText && (
              <Link
                url={ctaLink}
                className={`button solid-yellow ${slice.primary.cta_alignment}`}
              >
                {ctaText}
              </Link>
            )}
          </div>
        </div>
        <div className="products-wrapper">
          {renderProductsList(slice.items)}
        </div>
      </div>
    </section>
  );
}
