import {put} from "redux-saga/effects";

export function* initialAppLoad(api, router) {
  yield put({
    type: "PRISMIC_LOAD_SINGLE_PAGE",
    key: "site_settings",
  });

  yield put({type: "COOKIE_COMPLIANCE/CHECK"});
}
