import React, {useState, useEffect} from "react";
import PrismicRichText from "../components/PrismicRichText";
import {useLocation} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {get} from "lodash";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

export default function FormsSection(slice, directLink) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const location = useLocation();

  const urlHash = location.hash.substr(1);

  const foundUrlForm =
    slice.items.find((el) => el.title[0].text === directLink) ||
    slice.items.find((el) => el.title[0].text.toLowerCase() === urlHash) ||
    false;

  const [formsContainerRef, setFormsContainerRef] = useState(false);

  const [selectedForm, setSelectedForm] = useState(
    foundUrlForm ? foundUrlForm.title[0].text : slice.items[0].title[0].text
  );

  const [selectedFormBgColor, setSelectedFormBgColor] = useState(
    slice.items[0].background_color
  );

  const [selectedFormTextColor, setSelectedFormTextColor] = useState(
    slice.items[0].text_color
  );

  useEffect(() => {
    if (foundUrlForm && formsContainerRef) {
      setTimeout(() => {
        const headerHeight = document.getElementById("header").offsetHeight;
        const scrollPosition = formsContainerRef.offsetTop - headerHeight;

        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }, 1000);
    }
  }, [foundUrlForm, formsContainerRef]);

  const bgStyle = {
    backgroundColor: selectedFormBgColor,
    color: selectedFormTextColor,
  };

  const sectionAlignment = slice.primary.section_alignment;

  const renderPills = (items) => {
    return !isMobile && items.length > 3 ? (
      <FormControl>
        <TextField
          select
          name="select-form"
          value={selectedForm}
          onChange={(e) => {
            setSelectedForm(e.target.value);
          }}
          variant="outlined"
          style={{textAlign: "left"}}
          color="primary"
        >
          {items.map((el, i) => (
            <MenuItem
              data-bgcolor={el?.background_color}
              data-textcolor={el?.text_color}
              value={get(el, "title[0].text")}
            >
              {get(el, "title[0].text")}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    ) : (
      items.map((el, i) => (
        <div
          onClick={() => {
            setSelectedForm(el.title[0].text);
            setSelectedFormBgColor(el.background_color);
            setSelectedFormTextColor(el.text_color);
          }}
          key={i}
          className={`button outlined ${
            selectedForm === el.title[0].text ? "active" : false
          }`}
        >
          {el.title[0].text}
        </div>
      ))
    );
  };

  const renderImages = (items) => {
    return items.map((el, i) => (
      <div
        key={i}
        className={`form-image ${
          selectedForm === get(el, "title[0].text") ? "visible" : false
        }`}
      >
        <img src={get(el, "image.url")} alt="Good News Products" />
      </div>
    ));
  };

  const renderContent = (items) => {
    return items.map((el, i) => (
      <div
        key={i}
        className={`form-content ${
          selectedForm === el.title[0].text ? "visible" : false
        }`}
      >
        <PrismicRichText render={el?.content} />
      </div>
    ));
  };

  return (
    <section
      className="forms-section standard-padding"
      style={bgStyle}
      ref={(el) => setFormsContainerRef(el)}
    >
      <div className="wrapper">
        <div className="flex-wrapper">
          <div className="flex-child content-wrapper">
            <div className="text-content">
              <PrismicRichText render={slice.primary.content} />
            </div>
            <div className="pills">{renderPills(slice.items)}</div>
          </div>
          <div className="flex-child image-wrapper">
            <div className="content">{renderContent(slice.items)}</div>
            <div
              className={`form-images ${
                sectionAlignment === "Image / Content" && "lead-image"
              }`}
            >
              {renderImages(slice.items)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
