import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import Grid from "@material-ui/core/Grid";
import {get} from "lodash";

export default function RenderFeaturedSection(slice) {
  const backgroundStyle = {
    background: get(slice, "primary.background_color"),
    color: get(slice, "primary.text_color"),
  };

  const renderFeaturedItems = (items) => {
    return items.map((el, i) => (
      <Grid item key={i} className="featured-item" xs={12} sm={6}>
        <div className="image">
          <img src={get(el, "image.url")} alt="Hero Element" />
        </div>
        <div className="content">
          {<PrismicRichText render={el?.content} />}
        </div>
      </Grid>
    ));
  };
  return (
    <section
      className="featured-section standard-padding"
      style={backgroundStyle}
    >
      <Grid container className="wrapper">
        {renderFeaturedItems(slice?.items)}
      </Grid>
    </section>
  );
}
