import React, {useState} from "react";
import ShopPageDialog from "../components/Dialog/ShopPageDialog";

export default function PrismicPage(props) {
  const [extraClass, setExtraClass] = useState(false);

  setTimeout(() => {
    setExtraClass("yas");
  }, 200);

  return (
    <div className={`animation ${extraClass && extraClass}`}>
      <div className="simple-page full-screen-page">
        <ShopPageDialog />
      </div>
    </div>
  );
}
