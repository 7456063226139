import React from "react";
import URLCheckerLink from "./URLCheckerLinks";
const ReactRouterDomLink = require("react-router-dom").Link;

export default function Link({
  url, // external
  to = "/", // internal
  children,
  target,
  className,
  onClick,
}) {
  if (url) {
    return (
      <URLCheckerLink
        target={target}
        href={url}
        onClick={onClick}
        className={className}
        children={children}
        url={url}
      />
    );
  }

  return (
    <ReactRouterDomLink
      to={to}
      target={target}
      onClick={onClick}
      className={className}
    >
      {children}
    </ReactRouterDomLink>
  );
}
