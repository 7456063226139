import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";
import Link from "../components/Link";

export default function InstagramSection(slice) {
  console.log("slice");
  const renderPhotos = (items) => {
    return items.map((el, i) => (
      <img
        key={i}
        src={get(el, "photo.url")}
        alt="Good News Instagram Content"
      />
    ));
  };

  return (
    <section className="instagram-section standard-padding">
      <div className="wrapper">
        {get(slice, "primary.content") && (
          <PrismicRichText render={get(slice, "primary.content")} />
        )}

        {get(slice, "primary.cta_text[0].text") &&
          get(slice, "primary.cta_link[0].text") && (
            <div className="button-wrapper">
              <Link
                url={get(slice, "primary.cta_link[0].text")}
                className={`button solid-rose ${slice.primary.cta_alignment}`}
              >
                {get(slice, "primary.cta_text[0].text")}
              </Link>
            </div>
          )}

        <div className="photos">{renderPhotos(slice.items)}</div>
      </div>
    </section>
  );
}
