import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import Link from "../components/Link";

export default function RenderTextImageSection(slice) {
  const backgroundStyle = {
    background: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const sectionAlignment = slice.primary.section_alignment;
  const fullWidth = slice.primary.full_width;

  return (
    <section className="text-image-section" style={backgroundStyle}>
      <div className={`wrapper ${fullWidth === "Yes" && "full-width"}`}>
        <div
          className={`flex-wrapper ${
            sectionAlignment === "Image / Content" && "lead-image"
          }`}
        >
          <div className="content-wrapper standard-padding">
            <PrismicRichText render={slice.primary.content} />

            {slice.primary.cta_text.length > 0 &&
              slice.primary.cta_link.length > 0 &&
              slice.primary.cta_text[0].text &&
              slice.primary.cta_link[0].text && (
                <div className="button-wrapper">
                  <Link
                    url={slice.primary.cta_link[0].text}
                    className={`button solid-black ${slice.primary.cta_alignment}`}
                  >
                    {slice.primary.cta_text[0].text}
                  </Link>
                </div>
              )}
          </div>

          <div className="image-wrapper">
            <img src={slice.primary.image.url} alt="Good News Featured" />
          </div>
        </div>
      </div>
    </section>
  );
}
