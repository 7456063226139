import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function RenderTextSection(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const bgImage = isMobile
    ? slice.primary.mobile_background_image.url
    : slice.primary.desktop_background_image.url;

  const backgroundStyle = {
    background: slice.primary.background_color,
    backgroundImage: "url(" + bgImage + ")",
    color: slice.primary.text_color,
  };

  return (
    <section className="text-section standard-padding" style={backgroundStyle}>
      <div className="wrapper">
        <PrismicRichText render={slice.primary.content} />
      </div>
    </section>
  );
}
