import * as prismic from "@prismicio/client";

const endpoint = prismic.getRepositoryEndpoint("goodnews");
const accessToken =
  "MC5YczBrVUJFQUFDWUFIWklr.77-977-9G2hc77-9ITvvv73vv73vv71GCe-_ve-_vRR377-9Q--_vQUe77-977-977-9ee-_ve-_ve-_vXHvv73vv70"; // Set an access token
const routes = [];

const client = prismic.createClient(endpoint, {routes, accessToken});

export default client;
