import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import Hero from "../components/Hero";
import Slices from "../slices";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";


export default function PrismicPage(props) {
  const dispatch = useDispatch();

  const [extraClass, setExtraClass] = useState(false);
  const id = props.location.pathname.substr(1);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.custom_page.loading", true)
  );
  const page = useSelector((state) =>
    get(state, "prismic.custom_page.data", false)
  );
  const globalSlicesLoading = useSelector((state) =>
    get(state, "prismic.global_slices.loading", true)
  );
  const globalSlices = useSelector((state) =>
    get(state, "prismic.global_slices.data.body", false)
  );

  const richContent = get(page, "hero_section[0].rich_content");
  const buttons = get(page, "buttons");
  const ctaAlignment = get(page, "hero_section[0].cta_alignment");
  const backgroundColor = get(page, "hero_section[0].background_color");
  const backgroundImage = get(page, "hero_section[0].background_image.url");
  const mobileBackgroundImage = get(
    page,
    "hero_section[0].mobile_background_image.url"
  );
  const sectionAlignment = get(page, "hero_section[0].section_alignment");
  const showGlobalSlices = get(page, "global_slices");

  const slices = get(page, "body");

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "custom_page",
      id,
    });
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "global_slices",
    });
  }, [dispatch, id]);

  useEffect(() => {
    setExtraClass(false);
    setTimeout(() => {
      setExtraClass("yas");
    }, 200);
  }, [pageLoading]);

  if (pageLoading) {
    return (
      <CircularProgress
        style={{
          position: "fixed",
          color: "white",
          top: "calc(50% - 25px)",
          left: "calc(50% - 25px)",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  if (!page) {
    return <Redirect to={"/404"} />;
  }

  return (
    !pageLoading &&
    page && (
      <div className={`animation ${extraClass && extraClass}`}>
        <Hero
          richContent={richContent}
          sectionAlignment={sectionAlignment}
          ctaAlignment={ctaAlignment}
          backgroundColor={backgroundColor}
          buttons={buttons}
          backgroundImage={backgroundImage}
          mobileBackgroundImage={mobileBackgroundImage}
        />
        <Slices slices={slices}></Slices>
        {showGlobalSlices && !globalSlicesLoading && globalSlices && (
          <Slices slices={globalSlices}></Slices>
        )}
      </div>
    )
  );
}
