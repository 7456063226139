import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import Link from "../components/Link";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {get} from "lodash";

export default function RenderCardSwipeSection(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const backgroundStyle = {
    background: get(slice, "primary.background_color"),
    color: get(slice, "primary.text_color"),
  };

  const renderCards = (items) => {
    const bgColors = [];
    items.forEach((el) => {
      bgColors.push({
        background: el?.hover_overlay_color + "CF",
      });
    });
    return items.map((el, i) => (
      <div key={i} className="card">
        <Link
          to={{
            pathname: "/social-mindsets",
            directLink: get(el, "content[0].text"),
          }}
        >
          <div className="image">
            <img
              src={get(el, "image.url")}
              alt={`${get(el, "content[0].text")} graphic`}
            />
          </div>
          <div className="content" style={bgColors[i]}>
            <PrismicRichText render={el?.content} />
          </div>
        </Link>
      </div>
    ));
  };

  const renderCarousel = (items) => {
    const bgColors = [];
    items.forEach((el) => {
      bgColors.push({
        background: el.hover_overlay_color + "CF",
      });
    });
    return (
      <Carousel showStatus={false} showIndicators={false}>
        {items.map((el, i) => (
          <div key={i} className="card">
            <Link
              to={{
                pathname: "/social-mindsets",
                directLink: get(el, "content[0].text"),
              }}
            >
              <div className="image">
                <img
                  src={get(el, "image.url")}
                  alt={`${get(el, "content[0].text")} graphic`}
                />
              </div>
              <div className="content" style={bgColors[i]}>
                <PrismicRichText render={el?.content} />
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    );
  };

  return (
    <section
      className="card-swipe-section standard-padding"
      style={backgroundStyle}
    >
      <div className="wrapper">
        <div className="flex-wrapper">
          <div className="flex-child">
            <div className="content-wrapper">
              <PrismicRichText render={get(slice, "primary.content")} />
            </div>

            {get(slice, "primary.cta_text[0].text") &&
              get(slice, "primary.cta_link[0].text") && (
                <div className="button-wrapper">
                  <Link
                    url={get(slice, "primary.cta_link[0].text")}
                    className={`button solid-blue ${get(
                      slice,
                      "primary.cta_alignment"
                    )}`}
                  >
                    {get(slice, "primary.cta_text[0].text")}
                  </Link>
                </div>
              )}
          </div>
          <div className="flex-child">
            {isMobile ? (
              <div className="products-wrapper">
                {renderCards(slice?.items)}
              </div>
            ) : (
              <div className="products-wrapper products-carousel">
                {renderCarousel(slice?.items)}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
