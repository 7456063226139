import Prismic from 'prismic-javascript';
// -- Prismic API endpoint
const apiEndpoint = 'https://goodnews.cdn.prismic.io/api/v2';
// -- Access Token if the repository is not public
const accessToken =
  'MC5YczBrVUJFQUFDWUFIWklr.77-977-9G2hc77-9ITvvv73vv73vv71GCe-_ve-_vRR377-9Q--_vQUe77-977-977-9ee-_ve-_ve-_vXHvv73vv70';
// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, { accessToken });

export default PrismicClient;
