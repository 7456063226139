import React from "react";
import Home from "./pages/Home";
import ProductsPage from "./pages/Products";
import SocialMindsets from "./pages/SocialMindsets";
import AboutPage from "./pages/About";
import FAQPage from "./pages/Faq";
import PrismicPage from "./pages/PrismicPage";
import ShopPage from "./pages/ShopPage";
import StoreLocatorPage from "./pages/StoreLocatorPage";
import NotFound from "./pages/NotFound";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";

export default function Routes() {
  const {pathname} = useLocation();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/products" component={ProductsPage} />
      <Route path="/social-mindsets" component={SocialMindsets} />
      <Route path="/about" component={AboutPage} />
      <Route exact path="/contact">
        <Redirect to="/404" />
      </Route>

      <Route path="/find-us">
        <Redirect to="/store-locator" />
      </Route>
      <Route exact path="/store-locator" component={StoreLocatorPage} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/privacy-policy" component={PrismicPage} />
      <Route path="/terms-of-service" component={PrismicPage} />
      <Route path="/shop" component={ShopPage} />
      <Route path="/404" component={NotFound} />
      <Route path="/:id" component={PrismicPage} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
}
