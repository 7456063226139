import React from "react";
import RenderNewsletterSection from "./NewsletterSection";
import RenderInstagramSection from "./InstagramSection";
import RenderFormsSection from "./FormsSection";
import RenderMoodsSection from "./MoodsSection";
import RenderTextSection from "./TextSection";
import RenderTextImageSection from "./TextImageSection";
import RenderProductsSection from "./ProductsSection";
import RenderCardSwipeSection from "./CardSwipeSection";
import RenderFeaturedSection from "./FeaturedSection";
import RenderFAQSection from "./FAQSection";
import PDFSection from "./PDFSection";
import IFrameSlice from "./IFrameSlice";

export default function Slices(props) {
  if (!document) {
    return null;
  }
  return props.slices.map((slice, index) => (
    <div key={index}>
      {slice.slice_type === "featured_section" && RenderFeaturedSection(slice)}
      {slice.slice_type === "text_section" && RenderTextSection(slice)}
      {slice.slice_type === "products_section" && RenderProductsSection(slice)}
      {slice.slice_type === "card_swipe_section" &&
        RenderCardSwipeSection(slice)}
      {slice.slice_type === "text_image_section" &&
        RenderTextImageSection(slice)}
      {slice.slice_type === "newsletter_slice" &&
        RenderNewsletterSection(slice)}
      {slice.slice_type === "instagram_section" &&
        RenderInstagramSection(slice)}
      {slice.slice_type === "forms_section" && RenderFormsSection(slice, props.directLink)}
      {slice.slice_type === "moods_section" && RenderMoodsSection(slice)}
      {slice.slice_type === "faq_section" && RenderFAQSection(slice)}
      {slice.slice_type === "pdf_section" && PDFSection(slice)}
      {slice.slice_type === "iframe_slice" && IFrameSlice(slice)}
    </div>
  ));
}
