import React, {useState} from "react";
import PrismicRichText from "../components/PrismicRichText";

export default function RenderMoodsSection(slice) {
  const [selectedMood, setSelectedMood] = useState(
    slice.items[0].title[0].text
  );

  const renderPills = (items) => {
    return items.map((el, i) => (
      <div
        onClick={() => {
          setSelectedMood(el.title[0].text);
        }}
        key={i}
        className={`pill ${
          selectedMood === el.title[0].text ? "active" : false
        }`}
      >
        {el.title[0].text}
      </div>
    ));
  };

  const renderCards = (items) => {
    return items.map((el, i) => (
      <div
        key={i}
        className={`mood-card ${
          selectedMood === el.title[0].text ? "visible" : false
        }`}
      >
        <img src={el.image_1.url} alt="Moods of Good News" />

        <div className="content">
          <PrismicRichText render={el.content} />
        </div>
      </div>
    ));
  };

  const renderImages = (items) => {
    return items.map((el, i) => (
      <div
        key={i}
        className={`mood-image ${
          selectedMood === el.title[0].text ? "visible" : false
        }`}
      >
        <img src={el.image_2.url} alt="Moods of Good News" />
      </div>
    ));
  };

  return (
    <section className="moods-section standard-padding">
      <div className="wrapper">
        <div className="flex-wrapper">
          <div className="flex-child">
            <PrismicRichText render={slice.primary.content} />
          </div>
          <div className="flex-child pills">{renderPills(slice.items)}</div>
        </div>
        <div className="flex-wrapper mood-photography">
          <div className="flex-child mood-cards-wrapper">
            {renderCards(slice.items)}
          </div>
          <div className="flex-child mood-images-wrapper">
            {renderImages(slice.items)}
          </div>
        </div>
      </div>
    </section>
  );
}
